import React from 'react';
import { Container, Image, List, Segment, Grid } from 'semantic-ui-react';
import { withNamespaces, Link } from '../i18n';

const Footer = ({ t }) => {
	return (
		<Segment className="ca-footer" vertical>
			<Container textAlign="center">
				<div style={{ marginBottom: '20px' }}>
					<style jsx>{`
    @media (max-width: 767px) {
      .ca-mobile-two-column {
        display: flex;
        flex-wrap: wrap;
      }
      .ca-mobile-column {
        width: 50%;
        box-sizing: border-box;
        padding-right: 10px;
      }
    }
  `}</style>

					<Grid columns="equal" textAlign="left" doubling>
						<Grid.Column className="tablet only computer only four wide computer six wide tablet column"></Grid.Column>
						<Grid.Column className="twelve wide computer sixteen wide mobile ten wide tablet column" style={{ display: 'grid', textAlign: 'left', paddingLeft: '25px' }}>
							<div className="ca-mobile-two-column">
								<div  style={{marginBottom:"10px"}} className="ca-mobile-column">
									<List className="footer-list" link>
										<List.Item style={{ fontSize: '18px' }}>{t('toolsFooter')}</List.Item>
										<List.Item style={{ fontSize: '14px',marginBottom:"5px", paddingLeft: '15px' }}>
											<Link href="https://collectapi.com/tools/whatsapp-business-api-cost-calculator">
												<a target="_blank">{t('toolsOne')}</a>
											</Link>
										</List.Item>
										<List.Item style={{ fontSize: '14px', paddingLeft: '15px' }}>
											<Link href="https://collectapi.com/tools/online-image-editor">
												<a target="_blank">{t('toolsTwo')}</a>
											</Link>
										</List.Item>
									</List>
								</div>
								<div className="ca-mobile-column">
									<List className="footer-list" link>
										<List.Item style={{ fontSize: '18px' }}>{t('websiteexample')}</List.Item>
										<List.Item style={{ fontSize: '14px',marginBottom:"5px" ,paddingLeft: '15px' }}>
											<Link href="https://collectapi.com/web-examples/nobetci-eczane">
												<a target="_blank">{t('duty_pharmacy')}</a>
											</Link>
										</List.Item>
										<List.Item style={{ fontSize: '14px', paddingLeft: '15px' }}>
											<Link href="https://collectapi.com/web-examples/en-uygun-kredi-karsilastirma">
												<a target="_blank">{t('toolsThree')}</a>
											</Link>
										</List.Item>
									</List>
								</div>
							</div>
						</Grid.Column>
					</Grid>
				</div>
				<Grid columns="equal" textAlign="left" doubling>
					<Grid.Column className="tablet only computer only four wide computer six wide tablet column"></Grid.Column>
					<Grid.Column className="twelve wide computer sixteen wide mobile ten wide tablet column" style={{ display: 'grid', textAlign: 'left', paddingLeft: '25px' }}>
						<List className="footer-list" horizontal animated divided link>
							<List.Item>
								<Image alt="collectapi.com" width="35" heigth="35" src="/static/images/knot/knotapi@0,25x.png" centered size="mini" />
							</List.Item>
							<List.Item>
								<Link href="/contact">
									<a className="ui button-footer">{t('contact_us')}</a>
								</Link>
							</List.Item>
							<List.Item>
								<Link href="/terms">
									<a className="ui button-footer">{t('terms')}</a>
								</Link>
							</List.Item>
							<List.Item>
								<Link href="/privacy">
									<a className="ui button-footer">{t('privacy')}</a>
								</Link>
							</List.Item>
							<List.Item>
								<Link href="https://docs.collectapi.com">
									<a className="ui button-footer" target="_blank">
										{t('docs')}
									</a>
								</Link>
							</List.Item>
						</List>
					</Grid.Column>
				</Grid>
			</Container>
		</Segment>
	);
};

export default withNamespaces()(Footer);
